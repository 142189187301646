import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "ariaLabel", "promoData"];
var __jsx = React.createElement;
import React from 'react';
import ImageTextCard from '../../molecules/ImageTextCard';
import styled from 'styled-components';
import styles from './PromoBanner.style';
import { fetchProperty } from '../../../utils/utilityFunction';
import constants, { siteConstants } from '../../../constants/globalConstants';
import { useContentfulLiveUpdates } from "@contentful/live-preview/react";
import { ContentfulLivePreview } from '@contentful/live-preview';
var PromoBannerSection = styled.section.withConfig({
  componentId: "sc-15orjhq-0"
})(["", ";"], styles);
var PromoBanner = function PromoBanner(_ref) {
  var _textBlock$links, _textBlock$links$asse, _promoData$sys, _promoData$sys2, _promoData$sys3;
  var className = _ref.className,
    ariaLabel = _ref.ariaLabel,
    promoData = _ref.promoData,
    theme = _objectWithoutProperties(_ref, _excluded);
  var _useContentfulLiveUpd = useContentfulLiveUpdates(promoData),
    textBlock = _useContentfulLiveUpd.textBlock,
    cta = _useContentfulLiveUpd.cta,
    multipleCtaCollection = _useContentfulLiveUpd.multipleCtaCollection;
  var richTextEmbeddedAsset = textBlock && (textBlock === null || textBlock === void 0 ? void 0 : textBlock.links) && (textBlock === null || textBlock === void 0 ? void 0 : (_textBlock$links = textBlock.links) === null || _textBlock$links === void 0 ? void 0 : (_textBlock$links$asse = _textBlock$links.assets) === null || _textBlock$links$asse === void 0 ? void 0 : _textBlock$links$asse.block) || [];
  var description = textBlock && textBlock !== null && textBlock !== void 0 && textBlock.json ? textBlock === null || textBlock === void 0 ? void 0 : textBlock.json : false;
  //TODO: This supports the old data structure, once all queries have been updated this should be removed
  if (!description) {
    description = textBlock ? textBlock === null || textBlock === void 0 ? void 0 : textBlock.textBlock : '';
  }
  var anchorLink = promoData && (promoData === null || promoData === void 0 ? void 0 : promoData.anchorLink) || '';
  var attributes = {};
  if (anchorLink != '') attributes.id = anchorLink;
  if (className) attributes.className = "".concat(className, " nva-promo-banner");
  return __jsx(PromoBannerSection, attributes, __jsx(ImageTextCard, {
    styleType: "promo-banner",
    className: "nva-promo-banner__image-text-card promo-banner",
    paraClassName: "container",
    ariaLabel: fetchProperty(promoData, 'headline'),
    headingText: fetchProperty(promoData, 'headline'),
    headingType: "h2",
    subParaText: fetchProperty(promoData, 'subheadline'),
    categoryTitle: fetchProperty(promoData, 'categoryTitle'),
    subParaClassName: "heading_four",
    descriptionType: siteConstants.RICH_TEXT,
    descriptionText: description,
    richTextEmbeddedAsset: richTextEmbeddedAsset,
    ctaCollection: multipleCtaCollection,
    ctaSingle: cta,
    linkClassName: "promo-link",
    linkStyleType: "primary-anchor",
    dataAnalytics: {
      type: constants.analytics.analyticsType.promoBannerCTA,
      value: fetchProperty(promoData, 'headline')
    },
    contentfulLivePreviewHeadingText: ContentfulLivePreview.getProps({
      entryId: promoData === null || promoData === void 0 ? void 0 : (_promoData$sys = promoData.sys) === null || _promoData$sys === void 0 ? void 0 : _promoData$sys.id,
      fieldId: "headline"
    }),
    contentfulLivePreviewSubParaText: ContentfulLivePreview.getProps({
      entryId: promoData === null || promoData === void 0 ? void 0 : (_promoData$sys2 = promoData.sys) === null || _promoData$sys2 === void 0 ? void 0 : _promoData$sys2.id,
      fieldId: "subheadline"
    }),
    contentfulLivePreviewDescriptionText: ContentfulLivePreview.getProps({
      entryId: promoData === null || promoData === void 0 ? void 0 : (_promoData$sys3 = promoData.sys) === null || _promoData$sys3 === void 0 ? void 0 : _promoData$sys3.id,
      fieldId: 'textBlock'
    }),
    customCode: fetchProperty(promoData, 'customCode')
  }));
};
PromoBanner.defaultProps = {
  className: '',
  ariaLabel: ''
};
export default styled(PromoBanner).withConfig({
  componentId: "sc-15orjhq-1"
})(["", ";"], styles);